import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import React from 'react'
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import CookieConsent from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { BsArrowRight } from 'react-icons/bs';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('2354931594663372', options);
ReactPixel.revokeConsent();
ReactPixel.pageView(); // For tracking page view

function Header() {

  return (
    
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
<CookieConsent
      buttonStyle={{ borderRadius: 5 }}
      declineButtonStyle={{ borderRadius: 5 }}
      enableDeclineButton
      onAccept={(acceptedByScrolling) => {
        if (acceptedByScrolling) {
          // triggered if user scrolls past threshold
          ReactPixel.grantConsent();
        } else {
          ReactPixel.grantConsent();
        }
      }}
      >
  This website uses cookies to enhance the user experience.
</CookieConsent>
      <Container>
        
        <Navbar.Brand href="/">            
        <img
              src='/images/45south-logo-white.svg'
              alt='Logo'
              width="80"
              height="80"
              className="d-inline-block align-top"
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" >Home</Nav.Link>
            <Nav.Link as={Link} to="about" >About</Nav.Link>
            <Nav.Link as={Link} to="seasonalwork">Seasonal Work</Nav.Link>
            <Nav.Link as={Link} to="exports" >Exports</Nav.Link>
            <Nav.Link as={Link} to="contact" >Contact</Nav.Link>
          </Nav>
          {/* <Nav>
          <Nav.Link as={Link} to="apply" >
             Applications are now open! <BsArrowRight /> <Button variant="light">Apply Now!</Button>
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;